import { createRouter, createWebHistory } from 'vue-router'
import PageSignin from '../pages/PageSignin.vue'
import store from '../store/index.js'
import AccountPaidStatus from '@/shared/models/enums/account-paid-status.js'

const routes = [
    {
        path: '/signin',
        name: 'PageSignin',
        meta: { for_guest: true },
        component: PageSignin
    },
    {
        path: '/notation',
        name: 'PageNotation',
        meta: { for_guest: true },
        component: () => import('../pages/PageNotation.vue')
    },
    {
        path: '/privacy',
        name: 'PagePrivacy',
        meta: { for_guest: true },
        component: () => import('../pages/PagePrivacy.vue')
    },
    {
        path: '/',
        name: 'PageHome',
        component: () => import('../pages/PageHome.vue')
    },
    {
        path: '/inventory/:user(\\d+)?',
        name: 'PageInventory',
        component: () => import('../pages/PageInventory.vue')
    },
    {
        path: '/inventory/closed/:user(\\d+)?',
        name: 'PageInventoryClosed',
        component: () => import('../pages/PageInventory.vue')
    },
    {
        path: '/entry',
        name: 'PageEntry',
        component: () => import('../pages/PageEntry.vue')
    },
    {
        path: '/stats',
        name: 'PageStats',
        //meta: { gate: 'canViewStats' },   // アクセスはさせる・API側で条件制御
        component: () => import('../pages/PageStats.vue')
    },
    {
        path: '/preference',
        name: 'PagePreference',
        component: () => import('../pages/PagePreference.vue')
    },
    {
        path: '/setting/inventory',
        name: 'PageSettingInventory',
        meta: { gate: 'canManageSettingInventory' },
        component: () => import('../pages/PageSettingInventory.vue')
    },
    {
        path: '/setting/forbidden-asin',
        name: 'PageSettingForbiddenAsin',
        meta: { gate: 'canManageForbiddenAsin' },
        component: () => import('../pages/PageSettingForbiddenAsin.vue')
    },
    {
        path: '/setting/forbidden-maker',
        name: 'PageSettingForbiddenMaker',
        meta: { gate: 'canManageForbiddenMaker' },
        component: () => import('../pages/PageSettingForbiddenMaker.vue')
    },
    {
        path: '/setting/ignored-delaer',
        name: 'PageSettingIgnoredDealer',
        meta: { gate: 'canManageIgnoredDealer' },
        component: () => import('../pages/PageSettingIgnoredDealer.vue')
    },
    {
        path: '/setting/ignored-keyword',
        name: 'PageSettingIgnoredKeyword',
        meta: { gate: 'canManageIgnoredKeyword' },
        component: () => import('../pages/PageSettingIgnoredKeyword.vue')
    },
    {
        path: '/setting/spapi',
        name: 'PageSettingSpapi',
        //meta: { gate: 'canManageSpapi' }, アクセスはさせる・画面側で制御
        component: () => import('../pages/PageSettingSpapi.vue')
    },
    {
        path: '/payment',
        name: 'PagePayment',
        //meta: { gate: 'canManagePayment' }, アクセスはさせる・画面側で制御
        component: () => import('../pages/PagePayment.vue')
    },
    {
        path: '/manage/user',
        name: 'PageManageUser',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/PageManageUser.vue')
    },

    /**
     * エラー系
     */
    {
        //403ページ
        path: '/error/403',
        name: 'Error403',
        component: () => import('../pages/PageError403.vue')
    },
    {
        //404ページ ＝ 上記のいずれにもマッチしないもの
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: () => import('../pages/PageError404.vue')
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    console.log('beforeEach');
    if (to.matched.some(record => !record.meta.for_guest) && !store.state.user.auth.access_token) {
        //ログイン切れ
        next({ path: '/signin', query: { redirect: to.fullPath } });
    } else {
        if (
            store.state.user.auth.account
            && (
                store.state.user.auth.account.paid_status === AccountPaidStatus.PENDING
                || store.state.user.auth.account.paid_status === AccountPaidStatus.FAILED
            )
        ) {
            //未払
            if (to.matched.every(record => record.name !== 'PagePayment')) {
                console.log('payment');
                next({ path: '/payment' });
                return;
            }
            next();
            return;
        }

        if (
            store.state.user.auth.account
            && (
                !store.state.user.auth.account.spapi_merchant_id
                || store.state.user.auth.spapi_error_count >= 30
            )
        ) {
            //SP-API未登録 or エラー
            if (to.matched.every(record => record.name !== 'PageSettingSpapi')) {
                console.log('spapi');
                next({ path: '/setting/spapi' });
                return;
            }
            next();
            return;
        }

        for (let record of to.matched) {
            if (record.meta && record.meta.gate) {
                if (false === store.getters['user/auth/' + record.meta.gate]()) {
                    //権限なし
                    console.log('403');
                    next({ path: '/error/403' });
                    return;
                }
            }
        }

        next();
    }
});

export default router
